import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../firebase.init";



// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is your test publishable API key.
const stripePromise = loadStripe(process.env.REACT_APP_PK);

export default function MainCheckOut({ productSKU, imageUrl, sizeX, sizeY, price }) {
    const [clientSecret, setClientSecret] = useState("");
    const [user, loading] = useAuthState(auth);
    const [cart, setCart] = useState([]); // Initialize cart state
    const [cartTotal, setTotal] = useState(0); // Initialize cart state
    const [lightboxImage, setLightboxImage] = useState(null);

    // console.log(user);

    // data
    const itemSku = productSKU || 'GLOBAL-CFPM-16X20';
    const itemImageUrl = imageUrl || 'https://firebasestorage.googleapis.com/v0/b/ngen-art.appspot.com/o/images%2Fdna%2Fdna.jpeg00004b41-0f4b-4e6f-b3d2-8869b1a4a409?alt=media&token=af6b231f-d0ed-4960-8ce0-3a638bd8310d';
    const itemSizeX = sizeX || 16;
    const itemSizeY = sizeY || 20;
    const prodigiDetails = {
        "merchantReference": "MyMerchantReference1",
        "shippingMethod": "Overnight",
        "recipient": {
            "name": "Mr Testy McTestface",
            "address": {
                "line1": "14 test place",
                "line2": "test",
                "postalOrZipCode": "12345",
                "countryCode": "US",
                "townOrCity": "somewhere",
                "stateOrCounty": null
            },
            "email": "pcybriwsky@gmail.com",
        },
        "items": [
            {
                "merchantReference": "item #1",
                "sku": itemSku,
                "copies": 2,
                "sizing": "fillPrintArea",
                "attributes": {
                    "color": "black"
                },
                "recipientCost": {
                    "amount": price,
                    "currency": "USD"
                },
                "assets": [
                    {
                        "printArea": "default",
                        "url": itemImageUrl,
                        "md5Hash": "daa1c811c6038e718a23f0d816914b7b"
                    }
                ]
            }
        ],
        "metadata": {
            "mycustomkey": "some-guid",
            "someCustomerPreference": {
                "preference1": "something",
                "preference2": "red"
            },
            "sourceId": 12345
        }
    };

    const orderDetails = {
        "product": "COVERS-POSTER",
        "price": 30.00,
        "currency": "USD",
        "quantity": 1,
    }

    const openLightbox = (imageUrl) => {
        setLightboxImage(imageUrl);

    };

    const closeLightbox = () => {
        setLightboxImage(null);
    };

    const updateCart = async (items) => {
        setClientSecret('');
        try {
            let totalPrice = 0;
            // Update the client secret if the cart is successfully updated
            for (let i = 0; i < items.length; i++) {
                totalPrice += items[i].orderDetails.price;
            }

            setTotal(totalPrice);
            console.log(totalPrice);
            setCart(JSON.parse(localStorage.getItem("cart")))
            fetch(`${process.env.REACT_APP_SERVER_URL}/create-payment-intent`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    items: items,
                    email: user?.email,
                    name: user?.displayName,
                }),
            })
                .then((res) => res.json())
                .then((data) => setClientSecret(data.clientSecret));

        } catch (error) {
            console.error('Cart update error:', error.message);
        }
    };

    const addToCart = async () => {
        setClientSecret('');
        try {
            let items = JSON.parse(localStorage.getItem('cart')) || [];
            items.push({ prodigiDetails, orderDetails });
            localStorage.setItem('cart', JSON.stringify(items));

            // Trigger API call to update the cart on the server
            await updateCart(items);
        } catch (error) {
            console.error('Add to cart error:', error.message);
        }
    };

    const removeFromCart = (index) => {
        try {
            let items = JSON.parse(localStorage.getItem('cart')) || [];
            if (items.length > 0) {
                items.splice(index, 1); // Remove the item at the specified index
                localStorage.setItem('cart', JSON.stringify(items));
    
                // Trigger API call to update the cart on the server
                updateCart(items);
            } else {
                console.error('Cart is already empty.');
            }
        } catch (error) {
            console.error('Remove from cart error:', error.message);
        }
    };

    useEffect(() => {
        const storedCart = JSON.parse(localStorage.getItem("cart")) || [];
        setCart(storedCart);
        console.log(storedCart);
        let totalPrice = 0;
        // Update the client secret if the cart is successfully updated
        for (let i = 0; i < storedCart.length; i++) {
            totalPrice += storedCart[i].orderDetails.price;
        }

        setTotal(totalPrice);
        // Fetch the client secret when the component mounts or user changes
        fetch(`${process.env.REACT_APP_SERVER_URL}/create-payment-intent`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                items: storedCart,
                email: user?.email,
                name: user?.displayName,
            }),
        })
            .then((res) => res.json())
            .then((data) => setClientSecret(data.clientSecret));
    }, [user]);

    const appearance = {
        theme: 'stripe',
    };
    const options = {
        clientSecret,
        appearance,
    };

    return (
        <>
            <div className="grid grid-cols-1 w-[95%] laptop:grid-cols-2 gap-4 ">
                {lightboxImage && (
                    <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-jet bg-opacity-50 z-50" onClick={closeLightbox}>
                        <div className="modal-content">
                            <img src={lightboxImage} className="w-[50%] mx-auto" alt="Lightbox" />
                        </div>
                    </div>
                )}
                <div className="grid grid-cols-2 gap-4 py-[20px] w-[95%] px-[10px] my-[10px] text-xl bg-lightBackground mx-auto rounded">

                    {cart.map((item, index) => (
                        <>
                            <div key={index} className="my-auto flex">
                                <img src={item.prodigiDetails.items[0].assets[0].url} className="w-[150px] mx-auto border-2 border-jet" alt={item.orderDetails.product} onClick={
                                    () => openLightbox(item.prodigiDetails.items[0].assets[0].url)

                                } />
                                <div className="my-auto">
                                    <p className="my-auto mx-auto">{item.orderDetails.product}</p>
                                    {/* Update below to show sizing and add no frame to it */}

                                    {/* Need to update this with another definiton of sizing to be like the 12x14 or some shit */}
                                    <p className="my-auto mx-auto">{item.prodigiDetails.items[0].sku.split("-")[2].split("X")[0] + '" x ' + item.prodigiDetails.items[0].sku.split("-")[2].split("X")[1] + '"'}</p>
                                </div>


                            </div>
                            <div key={index} className="my-auto text-right text-2xl mx-auto w-[80%]">
                                {/* Can I show this in a way that's all the way on the side */}
                                <p className="text-right">${item.orderDetails.price}.00</p>
                                <p onClick={() => removeFromCart(index)} className="text-error hover:underline text-xs">remove</p>
                            </div>
                        </>
                    ))}
                    <div className="col-span-2 border-t-2 mx-auto w-[95%] border-gray-300"></div>


                    <div className="my-auto text-right mx-auto">
                        {/* Can I show this in a way that's all the way on the side */}
                    </div>
                    <div className="my-auto text-right text-2xl mx-auto w-[80%] py-[10px]">
                        <p className="font-bold">Subtotal: ${cartTotal}.00</p>
                    </div>
                </div>

                <div className="mt-4 flex-col items-center justify-center hidden ">
                    <p className="text-center">Total: ${cartTotal}.00</p>
                    <button onClick={addToCart} className="btn">Add to cart</button>
                    <button onClick={removeFromCart} className="btn">Remove from cart</button>
                </div>
                <div className="mx-auto w-[95%] my-auto py-[10px]">

                    {clientSecret && (
                        <Elements key={clientSecret} options={options} stripe={stripePromise}>
                            <CheckoutForm />
                        </Elements>
                    )}

                </div>
            </div>
        </>

    );
}