import React, { lazy, Suspense, Link } from 'react';
import Loading from '../Utils/Loading';
import Beta from '../Components/Beta/Beta'
import SEO from '../Components/SEO/SEO.js';
import LineAtoB from '../Components/Canvas/LineAtoB.js';

const AboutPage = () => {
    return (

        <>
            <SEO title="Introducing Dynamic Art | n-gen art" description="Learn more about the n-gen art beta and the creation of dynamic art! It takes only three steps to create and display your dynamic artwork." type="website" />
            <Suspense fallback={<Loading />}>
                <div className="w-[95%] laptop:w-[80%] mx-auto">
                    <LineAtoB />
                    <Beta />
                </div>
            </Suspense>
        </>
    );
};

export default AboutPage;