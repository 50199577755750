import React from 'react';
import p5 from 'p5';
import { InkLine } from '../Projects/Functions/InkLines';
import { Poly } from '../Projects/Functions/Watercolor';

class NLogo extends React.Component {

    constructor(props) {
        super(props);
        this.myRef = React.createRef();
        this.myP5 = null;
    }

    Sketch = (p) => {
        var ctx;
        var width = 300;
        var height = width

        var palette = ["#EA8B6A", "#6D8C7D", "#FF0022", "#0a0a0a", "#EA8B6A", "#247BA0"];

        let buff = 30;
        let inc = 10
        let weight = 1000;
        let drawing = null;
        let watercolorVector = [];
        let watercolor = null;

        p.preload = () => {
            p.ngen_font = p.loadFont('Assets/Fonts/SourceSerif4-Italic.ttf');
        }
        p.setup = () => {
            var fr = 60;
            ctx = p.createCanvas(300, 300);
            var canvas_holder = document.getElementById('block-canvas')
            let shapeFunction = p.random(1)
            let shape = 0
            if (shapeFunction < 0.5) {
                shape = 0
            } else {
                shape = 3
            }


            const shuffle = (array) => {
                for (let i = array.length - 1; i > 0; i--) {
                    const j = Math.floor(Math.random() * (i + 1));
                    [array[i], array[j]] = [array[j], array[i]];
                }
                return array;
            };
            shuffle(palette);
            ctx.parent(canvas_holder)
            p.frameRate(fr);
            drawing = new InkLine(palette, weight);
            drawing.setSplatter(0.98, 0.4, 1);
            drawing.setEndBubble(0.01);
            drawing.setAnalogueness(0.2, 4);
            drawing.setWeight(weight, 2 * weight)
            drawing.setDrawFunction(shape)
            drawing.setStops(2)

            p.textFont(p.ngen_font);
            p.textAlign(p.CENTER, p.CENTER);
            p.clear();
        }

        p.draw = () => {
            p.angleMode(p.DEGREES);
            drawing.animateLine(buff, height / 2, width - buff, height / 2, (p.frameCount - 1) * inc, p.frameCount * inc, p);

            if ((p.frameCount * inc) > weight) {
                // watercolor.colorIn(palette[0], palette[1], 1, p);
                // if(p.frameCount * inc > weight * 1.2) {
                // p.noLoop()
                // }
                p.noLoop();

            }

            if (p.frameCount == 1) {
                // let step = Math.round(drawing.pointsArray.length / 20);
                for (let k = 0; k < drawing.pointsArray.length; k++) {
                    p.angleMode(p.RADIANS)
                    watercolorVector.push(p.createVector(drawing.pointsArray[k].x, drawing.pointsArray[k].y));
                }
                watercolor = new Poly(watercolorVector, null, p);
            }
        }

        // Can update this to be something more
        p.updateLogo = () => {

        }

        p.getColor = () => {
            p.colorMode(p.RGB);
            let colors = palette;
            let colorsLength = colors.length

            let frames = 1000;
            let count = frames / colorsLength;

            let c1Index = Math.floor((p.frameCount / count) % colorsLength);
            let c2Index = c1Index + 1;

            if (c1Index == colorsLength) {
                c1Index = 0;
            }

            if (c2Index == colorsLength) {
                c2Index = 0;
            }

            let c1 = p.color(colors[c1Index])
            let c2 = p.color(colors[c2Index]);
            let fixedInter = p.map(p.frameCount % frames, c1Index * count, c2Index * count, 0, 1, true);

            let c = p.lerpColor(c1, c2, fixedInter);
            p.fill(c)
        }

        p.mouseClicked = () => {
            // if (p.mouseX > 0 && p.mouseX < p.width && p.mouseY > 0 && p.mouseY < p.height) {
            //     xLoc = p.mouseX;
            //     yLoc = p.mouseY;

            //     xDir = p.random(-1, 1);
            //     yDir = p.random(-1, 1);
            // }
        }
        
        p.startSketch = () => {
            p.loop(); // Start or resume the sketch
        };

        p.stopSketch = () => {
            p.noLoop(); // Pause the sketch
        };
    }

    componentDidMount() {
        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    // Element is in view, start the sketch
                    if (!this.myP5) {
                        this.myP5 = new p5(this.Sketch, this.myRef.current);
                        this.myP5.startSketch();
                    } else {
                        this.myP5.startSketch();
                    }
                } else {
                    // Element is out of view, pause the sketch
                    if (this.myP5) {
                        this.myP5.stopSketch();
                    }
                }
            });
        }, { threshold: 0.1 });

        if (this.myRef.current) {
            observer.observe(this.myRef.current);
        }
    }

    render() {
        return (
            <div ref={this.myRef} className="w-25 h-25 rounded-full mx-auto">
                {/* Sketch will render here */}
            </div>
        );
    }
}

export default NLogo;