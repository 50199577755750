import React from 'react';
import { BsFillTelephoneFill } from 'react-icons/bs'
import { BsFillEnvelopeFill } from 'react-icons/bs'
import { SiSubstack } from 'react-icons/si'
import { BsFacebook, BsSubstack, BsTiktok } from "react-icons/bs"
import { AiFillInstagram, AiFillTwitterCircle, AiFillLinkedin } from 'react-icons/ai'


const Footer = () => {
    let current_URL = window.location.href;
    if (current_URL.includes("DOM=")) {
        window.location.replace("https://www.ngenart.com");
    }

    if (current_URL.includes("https") == false && current_URL.includes("http") == false && current_URL.includes("localhost") == false && current_URL.includes("192.168.1.173") == false && current_URL.includes("192.168.1.196") == false && current_URL.includes("10.0.0.13") == false && current_URL.includes("10.0.0.20") == false) {
        window.location.replace("https://www.ngenart.com");
    }

    if (current_URL.includes("strava") == false && current_URL.includes("spotify") == false && current_URL.includes("https://ngenart") == false && current_URL.includes("https://www.ngenart") == false && current_URL.includes("192.168.1.196") == false && current_URL.includes("localhost") == false && current_URL.includes("192.168.1.173") == false && current_URL.includes("10.0.0.13") == false && current_URL.includes("10.0.0.20") == false) {
        console.log("Check triggered")
        window.location.replace("https://www.ngenart.com");
    }

    return (
        <div className='footerContainer w-full bg-jet'>
            <div className="footerContentContainer mx-auto w-[100%] laptop:w-[80%] py-[50px] px-[20px] ">

                <div className="footerTextContent grid grid-cols-1 desktop:grid-cols-2 laptop:grid-cols-2">
                    <div className="my-auto">
                        {/* Add n-gen logo over this? Or add email to the social */}
                        <div className="flex justify-center items-center">
                            <img src="/Assets/Images/ngenLogoWhiteFooter.png" className="w-[100px] laptop:w-[100px]mx-auto" />
                        </div>
                    </div>
                    <div>
                        <div className="grid grid-cols-2 laptop:grid-cols-5 mx-auto py-[10px]">
                            <div className="mx-auto col-span-1">
                                <a href={'/'}><p className='footerText'>Home</p></a>
                            </div>

                            <div className="mx-auto col-span-1">
                                <a href={'/about'}><p className='footerText'>About</p></a>
                            </div>

                            <div className="mx-auto col-span-1">
                                <a href={'/create'}><p className='footerText'>Create</p></a>
                            </div>

                            <div className="mx-auto col-span-1">
                                <a href={'/work'}><p className='footerText'>Work With Us</p></a>
                            </div>
                            
                            <div className="mx-auto col-span-2 laptop:col-span-1">
                                <a href={'/contact'}><p className='footerText'>Contact</p></a>
                            </div>
                        </div>

                        <div className='py-[10px]'>
                            <div className="contactWrapper flex justify-center items-center mx-auto">
                                <div className='w-[80%] mx-auto grid grid-cols-6'>
                                    <a href="mailto:info@ngenart.com" className='mx-auto'>
                                        <BsFillEnvelopeFill className='footerIcon' />
                                    </a>

                                    <a href='https://www.instagram.com/ngenart_co/' className='mx-auto'>
                                        <AiFillInstagram className='footerIcon' />
                                    </a>

                                    <a href='https://twitter.com/ngenart_co' className='mx-auto'>
                                        <AiFillTwitterCircle className='footerIcon' />
                                    </a>

                                    <a href='https://www.tiktok.com/@ngenart_co' className='mx-auto'>
                                        <BsTiktok className='footerIcon' />
                                    </a>

                                    <a href='https://ngenart.substack.com?utm_source=navbar&utm_medium=web&r=27olti' className='mx-auto'>
                                        <SiSubstack className='footerIcon' />
                                    </a>

                                    <a href='https://www.linkedin.com/company/n-gen/' className='mx-auto'>
                                        <AiFillLinkedin className='footerIcon' />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>

            </div>
        </div>
    );
};

export default Footer;