import React from 'react';
import BdStyle from './Backdrops.module.css'

const Backdrops = () => {
    return (
        <div className={BdStyle.backdrop}>
        </div>
    );
};

export default Backdrops;