import React, { useState, useEffect } from 'react';
import { RiMenu3Line, RiCloseFill } from 'react-icons/ri'
import { signOut } from 'firebase/auth';
import { FiLogOut } from 'react-icons/fi'
import { LuShoppingBag } from 'react-icons/lu'
import { auth } from '../firebase.init'
import Style from './Navbar.module.css'
import ToastNotification from '../Components/PageBlocks/ToastNotification';
import LoadingBlock from '../Components/PageBlocks/StravaLoadingNotification.js';
import LoadedBlock from '../Components/PageBlocks/StravaLoaded.js';
import NoDataBlock from '../Components/PageBlocks/StravaNoData.js';

const Navbar = React.memo(({ isShow, setShow, authUser }) => {
    const [cartCount, setCartCount] = useState(0);
    const [drop, setDrop] = useState(true)
    const [showToast, setShowToast] = useState(false) // Set to true for testing purposes -- live will be seet to false
    const [showStravaLoading, setShowStravaLoading] = useState(false);
    const [showStravaLoaded, setShowStravaLoaded] = useState(false);
    const [showStravaNoData, setShowStravaNoData] = useState(false);

    const handleMenu = () => {
        setShow(!isShow)
        if (!isShow) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }
    }
    let userId = null
    if (auth.currentUser !== null) {
        userId = auth.currentUser.uid;
    }
    else {
        userId = null;
    }

    const showToastNotification = () => {
        setShowToast(true)
    }
    
    const showStravaLoadingNotification = () => {
        setShowStravaLoading(true);
    }

    const showStravaLoadedNotification = () => {
        setShowStravaLoading(false);
        setShowStravaLoaded(true);
    }

    useEffect(() => {
        const handleCartUpdate = () => {
            const cart = JSON.parse(localStorage.getItem('cart'));
            setCartCount(cart ? cart.length : 0);
        };

        // Add event listener for the custom 'cartUpdated' event
        window.addEventListener('cartUpdated', handleCartUpdate);
        window.addEventListener('cartUpdated', showToastNotification);
        window.addEventListener('stravaLoading', showStravaLoadingNotification);
        window.addEventListener('stravaLoaded', showStravaLoadedNotification);
        window.addEventListener('stravaNoData', () => {
            setShowStravaLoading(false);
            setShowStravaNoData(true);
        });
    
        // Initial cart count update
        handleCartUpdate();

        // Cleanup the event listener
        return () => {
            window.removeEventListener('cartUpdated', handleCartUpdate);
            window.removeEventListener('stravaLoading', showStravaLoadingNotification);
        };
    }, []);

    return (
        <div>
            <div style={{ zIndex: "9999" }} className='navBar_Container w-full bg-lightBackground h-[100px] z-50'>
                <div className="navbarContentContainer mx-auto max-w-[1400px] flex justify-between items-center px-4 h-full">

                    <p className='w-full mb-2 text-7xl text-jet italic font-serif'><a href={'/'}>n</a></p>

                    <ul className='hidden justify-center text-center items-center whitespace-nowrap text-lg laptop:flex desktop:flex'>
                        <li className='nav'><a href='/create'>Create</a></li>
                        <li className='nav'><a href='/work'>Work With Us</a></li>
                        <li className='nav'><a href={'/gallery'}>Gallery</a></li>
                        <li className='nav'><a href={'/about'}>About</a></li>
                        <li className='nav'><a href='https://ngenart.substack.com/?utm_source=substack&utm_medium=web&utm_campaign=substack_profile'>Blogs</a></li>
                        {
                            authUser ?
                                <>
                                    <li className='nav'><a href='/profile'>Profile</a></li>
                                </>
                                :
                                null
                        }

                        {/* Shopping bag hidden here */}
                        <li className='nav relative text-xl hidden'><a href='/checkout'><LuShoppingBag /> 
                            {cartCount > 0 && (
                                <span className="absolute top-0 left-5 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-white transform translate-x-1/2 -translate-y-1/2 bg-deepGreen rounded-full">
                                    {cartCount}
                                </span>
                            )}
                        </a>
                        </li>


                        <li className='hidden'>
                            {
                                authUser ?
                                    <div className="">
                                        <div className={`${Style.dropdownMenu} inline-block relative`}>

                                            {authUser.email !== undefined && authUser.email !== null ?
                                                <button className="bg-gray-300 text-gray-700 font-semibold py-2 px-4 rounded inline-flex items-center" onClick={() => setDrop(!drop)}>
                                                    <span className="mr-3 w-[200px] text-lg text-deepOrange text-center font-bold lowercase font-serif italic">Welcome {authUser.displayName != null ? authUser.displayName.slice(0, 8) : authUser.email != undefined || authUser.email != null ? authUser.email.split('@')[0].slice(0, 8) : "Guest"}
                                                    </span>
                                                    {/* <img src={authUser.photoURL} className='w-[40px] h-[40px] border-jet border-2 rounded-full' alt="" /> */}
                                                    {/* <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /> </svg> */}
                                                </button>

                                                :
                                                <a href='https://ngenart.com/login'>
                                                    <button className='border-2 border-jet  bg-jet w-[142px] h-[44px] border-rad text-white rounded-[4px] '>Login</button>
                                                </a>


                                            }


                                            <ul className={`${!drop ? Style.dropdownMenu : " "} absolute top-[65px] left-[60%] h-[60px] w-[100px] hidden text-gray-700 py-[20px] cursor-pointer  bg-lightBackground`} onClick={async () => {
                                                signOut(auth)
                                                await window.Verisoul.reinitialize()
                                            }}>
                                                <li className="text-sm text-center">
                                                    <FiLogOut className='inline-block mr-2' />
                                                    Logout
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    :
                                    <a href='https://ngenart.com/login'>
                                        <button className='border-2 border-deepOrange bg-deepOrange w-[142px] h-[44px] border-rad text-white rounded-[4px] '>Login</button>
                                    </a>
                            }
                        </li>
                    </ul>


                    <div className="mobileView inline-block laptop:hidden desktop:hidden">
                        <div className="mobileViewIcons flex justify-center items-center font-bold text-lg">


                            {
                                authUser != undefined ?
                                    <>
                                        {authUser.email !== undefined && authUser.email !== null ?
                                            <>
                                                {/* <span className="mr-3 w-[200px] text-base text-center text-deepOrange font-bold lowercase font-serif italic">Welcome {authUser.displayName != null ? authUser.displayName : authUser.email.split('@')[0]}
                                            </span> */}
                                                {/* <img src={authUser.photoURL} className='w-[40px] h-[40px] border-jet border-2 rounded-full' alt="" /> */}
                                            </>
                                            :
                                            <a href='https://ngenart.com/login'>
                                                <button className='border-2 border-jet bg-jet w-[100px] h-[44px] border-rad text-white rounded-[4px] mr-10 '>Login</button>
                                            </a>


                                        }
                                    </>

                                    :
                                    <a href='https://ngenart.com/login'>
                                        <button className='border-2 border-jet bg-jet w-[100px] h-[44px] border-rad text-white rounded-[4px] mr-10 '>Login</button>
                                    </a>
                            }



                            <div className={!isShow ? "menuIcons z-[200] relative top-0 right-2 ease-in-out duration-300 desktop:hidden laptop:hidden" : "right-[-100%]"} onClick={() => handleMenu()}>
                                {
                                    !isShow && <RiMenu3Line size={25} />
                                }
                            </div>

                        </div>
                        <div className={isShow ? "mobileViewNavbarList absolute right-0 top-0 w-[60%] h-full border-r border-sand bg-lightBackground ease-in-out duration-300 z-[200]" : "fixed right-[-100%]"}>
                            <div className="closIcon w-full mt-10 pr-10 flex justify-end items-center" onClick={() => handleMenu()}>
                                <RiCloseFill size={25} />
                            </div>
                            <h2 className='w-full text-7xl text-jet italic font-serif text-center'>n</h2>
                            <ul onClick={() => handleMenu()} className='pt-12 w-full text-center'>
                                {authUser ?
                                    <>
                                        {/* <img src={authUser.photoURL} className='w-[40px] h-[40px] border-jet border-2 rounded-full mx-auto' alt="" /> */}

                                        <span className="hidden mr-3 w-[200px] text-base text-center mx-auto text-deepOrange font-bold lowercase font-serif italic">Welcome {authUser.displayName != null ? authUser.displayName.slice(0, 8) : authUser.email != undefined || authUser.email != null ? authUser.email.split('@')[0].slice(0, 8) : "Guest"}

                                        </span>

                                    </>
                                    : null}
                                <li className='p-6 nav'><a href='/create'>Create</a></li>
                                <li className='p-6 nav'><a href='/work'>Work With Us</a></li>
                                <li className='p-6 nav'><a href={'/gallery'}>Gallery</a></li>
                                <li className='p-6 nav'><a href={'/about'}>About</a></li>
                                <li className='p-6 nav'><a href={'https://ngenart.substack.com/?utm_source=substack&utm_medium=web&utm_campaign=substack_profile'}>Blogs</a></li>


                                {
                                    authUser ?
                                        <>
                                            {/* <li className='p-6'><a href={`/gallery/${userId}`}>Your Gallery</a></li> */}
                                            <li className='p-6'><a href='/profile'>Profile</a></li>
                                        </>
                                        :
                                        null
                                }

                                <li >
                                    {
                                        authUser ?
                                            <div className="p-10">
                                                <ul className={`text-gray-700 py-[20px] cursor-pointer bg-lightBackground`} onClick={async () => {
                                                    signOut(auth)
                                                    await window.Verisoul.reinitialize();
                                                }}>
                                                    <li className="text-base text-center">
                                                        <a href="/">
                                                            <FiLogOut className='inline-block mr-2' />
                                                            Logout
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                            :
                                            <a href='https://ngenart.com/login'>
                                                <button className='border-2 border-jet bg-jet w-[142px] h-[44px] border-rad text-white rounded-[4px] '>Login</button>
                                            </a>
                                    }
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            {showToast && <ToastNotification item="Sample Item" onClose={() => setShowToast(false)} />}
            {showStravaLoading && <LoadingBlock onClose={() => setShowStravaLoading(false)} />}
            {showStravaLoaded && <LoadedBlock onClose={() => setShowStravaLoaded(false)} />}
            {showStravaNoData && <NoDataBlock onClose={() => setShowStravaNoData(false)}/>}
        </div>
    );
});

export default Navbar;
