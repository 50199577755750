import React from 'react';

const ToastNotification = ({ onClose }) => {
    

    return (
        <div className="fixed bottom-5 right-5 bg-white border border-jet rounded-lg shadow-lg p-4 max-w-sm flex items-center space-x-4">
            <div className="flex-grow">
                {/* <div className="font-bold">Item Added to Cart</div> */}
                <div className=''>Loading your latest Strava data, please hold...</div>
            </div>
            <button className="text-jet opacity-[80%] hover:opacity-[100%] ease-linear duration-150" onClick={onClose}>
                <span aria-hidden="true">×</span>
            </button>
        </div>
    );
};

export default ToastNotification;