import { useState } from "react"
import React, { lazy, Suspense } from "react"
import { Route, Routes } from "react-router-dom"
import { HelmetProvider } from "react-helmet-async"

// import LandingPage from "./Pages/LandingPage"
import Backdrops from "./Utils/Backdrops/Backdrops"
import Footer from "./Utils/Footer/Footer"
import Navbar from "./Utils/Navbar"
// import SelectDesignPage from "./Pages/SelectDesignPage"
import AboutPage from "./Pages/AboutPage"
// import LoginPage from "./Pages/Auth/LoginPage"
// import SignupPage from "./Pages/Auth/SignupPage"
import Loading from "./Utils/Loading"
import { ToastContainer } from 'react-toastify';
import { useAuthState } from 'react-firebase-hooks/auth';
import 'react-toastify/dist/ReactToastify.css';
import { auth } from "./firebase.init";
import AuthRoute from "./Components/Auth/AuthRoute"
import BloomAuthRoute from "./Components/Auth/BloomAuthRoute"
import ChordsAuthRoute from "./Components/Auth/ChordsAuthRoute"
import TopTenAuthRoute from "./Components/Auth/TopTenAuthRoute"
import MainCheckOut from "./Pages/Checkout/MainCheckOut"

const LandingPage = lazy(() => import("./Pages/LandingPage"))
const WorkWithUsPage = lazy(() => import("./Pages/WorkWithUsPage"))
const SelectDesignPage = lazy(() => import("./Pages/SelectDesignPage"))
const ContactUsPage = lazy(() => import("./Pages/ContactUsPage"))

const LoginPage = lazy(() => import("./Pages/Auth/LoginPage"))
const SignupPage = lazy(() => import("./Pages/Auth/SignupPage"))
const IslandsPage = lazy(() => import("./Components/Projects/Oasis/OasisPage"))
const TrailblazerPage = lazy(() => import("./Components/Projects/Trailblazers/TrailblazerPage"))
const PathsPage = lazy(() => import("./Components/Projects/Paths/PathsPage"))
const ProfilePage = lazy(() => import("./Pages/ProfilePage"))
const Privacy = lazy(() => import("./Pages/PrivacyPage"))
const BloomPage = lazy(() => import("./Components/Projects/Bloom/SpotifyFlower"))
const GardenPage = lazy(() => import("./Components/Projects/Bloom/GardenPage"))
const BloomAuthPage = lazy(() => import("./Pages/BloomAuthPage"))
const ChordsAuthPage = lazy(() => import("./Pages/ChordsAuthPage"))
const TrailsPage = lazy(() => import("./Components/Projects/Trailblazers/TrailsPage"))
const GridPage = lazy(() => import("./Components/Projects/Glyphs/ActivityGridPage"))
const GlyphsPage = lazy(() => import("./Components/Projects/Glyphs/GlyphsGalleryPage"))
const GalleryOverview = lazy(() => import("./Components/Integrations/GalleryOverview"))
const UserGallery = lazy(() => import("./Pages/UserGalleryPage"))
const PathsGallery = lazy(() => import("./Components/Projects/Paths/PathsGalleryPage"))
const WorldGallery = lazy(() => import("./Components/Galleries/WorldGallery"))

const StravaPage = lazy(() => import("./Pages/StravaPage"))
const SpotifyPage = lazy(() => import("./Pages/SpotifyPage"))
const LocationPage = lazy(() => import("./Pages/LocationPage"))
const ChordsPage = lazy(() => import("./Components/Projects/Chords/ChordsPage"))
const ChordsGallery = lazy(() => import("./Components/Projects/Chords/ChordsGalleryPage"))
const OasesGallery = lazy(() => import("./Components/Projects/Oasis/OasesPage"))
const TopTenPage = lazy(() => import("./Components/Projects/Setlist/SetlistPage"))
const TopTenGallery = lazy(() => import("./Components/Projects/Setlist/SetlistGalleryPage"))
const TopTenAuthPage = lazy(() => import("./Pages/TopTenAuthPage"))
const SoundscapePage = lazy(() => import("./Components/Projects/Scapes/ScapesPage"))
const DnaPage = lazy(() => import("./Components/Projects/Dna/DnaPage"))
const DnaGallery = lazy(() => import("./Components/Projects/Dna/DnaGalleryPage"))
const DnaAuthPage = lazy(() => import("./Pages/DnaAuthPage"))
const AlbumsPage = lazy(() => import("./Components/Projects/Albums/AlbumsPage"))
const LegendsPage = lazy(() => import("./Components/Projects/Legends/LegendsPage"))
const PersonasPage = lazy(() => import("./Components/Projects/Personas/PersonasPage"))
const PersonasAuthPage = lazy(() => import("./Pages/PersonasAuthPage"))
const PersonasGallery = lazy(() => import("./Components/Projects/Personas/PersonasGalleryPage"))
const GenrePage = lazy(() => import("./Components/Projects/Genres/GenresPage"))
const CoversPage = lazy(() => import("./Components/Projects/Covers/CoversPage"))
const QueuePage = lazy(() => import("./Components/Projects/Queue/QueuePage"))
const CalendarPage = lazy(() => import("./Components/Projects/Calendar/CalendarPage"))
const NowPlayingPage = lazy(() => import("./Components/Projects/NowPlaying/NowPlayingPage"))
const NowPlayingAuthPage = lazy(() => import("./Pages/NowPlayingAuthPage"))


const TestPage = lazy(() => import("./Components/Projects/Testing/TestPage"))
const LoveLettersPage = lazy(() => import("./Components/Projects/LoveLetters/LettersPage"))

const ReceiptsPage = lazy(() => import("./Components/Projects/Receipts/ReceiptsPage"))
const ReceiptsAuthPage = lazy(() => import("./Pages/ReceiptsAuthPage"))

const OdometerPage = lazy(() => import("./Components/Projects/Odometer/OdometerPage"))

const PathsReworkPage = lazy(() => import("./Components/Projects/PathsRework/PathsReworkPage"))
const MarauderPage = lazy(() => import("./Components/Projects/Marauder/MarauderPage"))

const GarminPage = lazy(() => import("./Components/Projects/Garmin/GarminPage"))
const GarminLogin = lazy(() => import("./Components/Login/GarminLogin"))

const CoversAuthPage = lazy(() => import("./Pages/CoversAuthPage"))
const TimelinesAuthPage = lazy(() => import("./Pages/TimelinesAuthPage"))
const CalendarAuthPage = lazy(() => import("./Pages/CalendarAuthPage"))

const BlogOverviewPage = lazy(() => import("./Components/Blogs/BlogOverviewPage"))
const BlogIntroToNGen = lazy(() => import("./Components/Blogs/092623BlogIntroToNGen"))

const StandardNotFound = lazy(() => import("./Pages/NotFoundPage"))

const AddToCartPage = lazy(() => import("./Pages/TestCartPage"))
const OrderStatusPage = lazy(() => import("./Pages/Orders/OrderStatus"))
const OrderConfirmationPage = lazy(() => import("./Pages/Orders/OrderConfirmation"))

const SmokePage = lazy(() => import("./Components/Projects/TheDevice/SmokePage"))






function App() {
	const [isShow, setShow] = useState(false)
	const [user, loading] = useAuthState(auth);
	if (loading) {
		return <Loading />
	}

	return (
		<>
			<HelmetProvider>
				<Navbar isShow={isShow} setShow={setShow} authUser={user} />

				{
					isShow && <Backdrops />
				}
				<Routes>


					<Route path="/" element={<LandingPage />} />

					<Route path="/create">
						<Route index element={<SelectDesignPage />} />
						<Route path=":id" element={
							<AuthRoute>
								{/* create design page */}
							</AuthRoute>
						} />
					</Route>
					<Route path="/privacy" element={<Privacy />}> </Route>
					<Route path="/login" element={<LoginPage />} />
					<Route path="/signup" element={<SignupPage />} />

					<Route path="/about" element={<AboutPage />} />
					<Route path="/work" element={<WorkWithUsPage />} />
					<Route path="/contact" element={<ContactUsPage />} />
					{/* <Route path="/callback" element={<CallBackPage />} />
				<Route path="/localcallback" element={<LocalCallbackPage />} /> */}

					<Route path="/loading" element={<Loading />} />

					<Route path="/profile" element={<AuthRoute><ProfilePage /></AuthRoute>} />

					{/* Weather */}
					<Route path="/location" element={<LocationPage />} />
					<Route path="/location/oasis" element={<AuthRoute><IslandsPage /></AuthRoute>} />


					{/* Races */}

					{/* Strava */}
					<Route path="/strava" element={<StravaPage />}></Route>
					<Route path="/strava/trailblazer" element={<AuthRoute><TrailblazerPage /></AuthRoute>} />
					<Route path="/strava/paths" element={<AuthRoute><PathsPage /></AuthRoute>} />
					<Route path="/strava/glyphs" element={<AuthRoute><GridPage /></AuthRoute>} />
					<Route path="/strava/legends" element={<AuthRoute><LegendsPage /></AuthRoute>} />
					<Route path="/strava/pathsrework" element={<AuthRoute><PathsReworkPage /></AuthRoute>} />
					
					<Route path="/strava/receipts" element={<ReceiptsPage />} />
					<Route path="/strava/login-receipts" element={<ReceiptsAuthPage />} />

					<Route path="/strava/odometer" element={<OdometerPage />} />

					<Route path="/strava/marauder" element={<AuthRoute><MarauderPage /></AuthRoute>} />

					<Route path="/garmin/test" element={<GarminPage />} />
					<Route path="/garmin/login" element={<GarminLogin />} />



					{/* Spotify */}
					<Route path="/spotify" element={<SpotifyPage />}></Route>
					<Route path="/spotify/login-bloom" element={<BloomAuthPage />} />
					<Route path="/spotify/login-chords" element={<ChordsAuthPage />} />
					<Route path="/spotify/login-ten" element={<TopTenAuthPage />} />
					<Route path="/spotify/login-dna" element={<DnaAuthPage />} />
					<Route path="/spotify/login-personas" element={<PersonasAuthPage />} />
					<Route path="/spotify/login-covers" element={<CoversAuthPage />} />
					<Route path="/spotify/login-timelines" element={<TimelinesAuthPage />} />
					<Route path="/spotify/login-calendar" element={<CalendarAuthPage />} />
					<Route path="/spotify/login-nowplaying" element={<NowPlayingAuthPage />} />



					<Route path="/spotify/login" element={<BloomAuthPage />} />
					<Route path="/spotify/bloom" element={<BloomAuthRoute><BloomPage /> </BloomAuthRoute>} />
					<Route path="/spotify/chords" element={<ChordsAuthRoute><ChordsPage /> </ChordsAuthRoute>} />
					<Route path="/spotify/topten" element={<TopTenAuthRoute><TopTenPage /></TopTenAuthRoute>} />
					<Route path="/spotify/soundscape" element={<SoundscapePage />} />
					<Route path="/spotify/dna" element={<DnaPage />} />
					<Route path="/spotify/albums" element={<AlbumsPage />} />
					<Route path="/spotify/personas" element={<PersonasPage />} />
					<Route path="/spotify/timelines" element={<GenrePage />} />
					<Route path="/spotify/covers" element={<CoversPage />} />
					<Route path="/spotify/queue" element={<QueuePage />} />
					<Route path="/spotify/calendar" element={<CalendarPage />} />
					<Route path="/spotify/nowplaying" element={<NowPlayingPage />} />

					<Route path="/spotify/*" element={<StandardNotFound />} />
					<Route path="/strava/*" element={<StandardNotFound />} />
					<Route path="/*" element={<StandardNotFound />} />
					<Route path="/404" element={<StandardNotFound />} />

					<Route path="/device/smoke" element={<SmokePage />} />

					

					{/* <Route path="/spotify/paths" element={<AuthRoute><PathsPage /></AuthRoute>} /> */}



					{/* Gallery */}

					<Route path="/gallery" element={<GalleryOverview />} />

					<Route path="/gallery/world" element={<WorldGallery />} />

					<Route path="/gallery/:id" element={<UserGallery />} />


					<Route path="/gallery/trails" element={<TrailsPage />} />
					<Route path="/gallery/garden" element={<GardenPage />} />
					<Route path="/gallery/glyphs" element={<GlyphsPage />} />
					<Route path="/gallery/paths" element={<PathsGallery />} />
					<Route path="/gallery/chords" element={<ChordsGallery />} />
					<Route path="/gallery/oases" element={<OasesGallery />} />
					<Route path="/gallery/topTen" element={<TopTenGallery />} />
					<Route path="/gallery/dna" element={<DnaGallery />} />
					<Route path="/gallery/personas" element={<PersonasGallery />} />


					{/* Blogs */}
					<Route path="/blogs" element={<BlogOverviewPage />} />
					<Route path="/blogs/intro-to-ngen" element={<BlogIntroToNGen />} />

					{/* In-Beta -- move when in full-site */}
					<Route path="/cart" element={<AddToCartPage />} />
					{/* checkout */}
					<Route path="/checkout" element={<MainCheckOut></MainCheckOut>} />

					{/* Orders */}
					<Route path="/orders" element={<OrderStatusPage />} />
					<Route path="/orders/confirmation" element={<OrderConfirmationPage />} />
					<Route path="/orders/*" element={<StandardNotFound />} />
					<Route path="/test" element={<TestPage />} />
					<Route path="/letters" element={<LoveLettersPage />} />

				
            <Route path="/spotify/nowplaying" element={<NowPlayingPage />} />
            <Route path="/spotify/login-nowplaying" element={<NowPlayingAuthPage />} />
    </Routes>

				<Footer />
				<ToastContainer
					position="top-center"
					autoClose={5000}
					hideProgressBar={false}
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnFocusLoss
					draggable
					pauseOnHover
					theme="light"
				/>
			</HelmetProvider>
		</>
	)

}

export default App