import React from 'react';
let isMobile = window.innerWidth < 768;

export default function InfoBlock({ title, description, description2, description3, imageFile, headline, link, orientation, canvas }) {

    return (
        <div className='grid grid-cols-1 laptop:grid-cols-2 mx-auto items-center py-[10px]'>
            {/* Headline */}

            <div className='w-[100%] laptop:w-[100%] mx-auto my-auto'>
                <p className="text-md w-[100%] laptop:w-[100%] uppercase text-center laptop:text-left mx-auto text-jet pt-[20px]">
                    {headline}
                </p>
                <div className='grid grid-cols-1 laptop:grid-cols-1 mx-auto  text-center laptop:text-left'>
                    <p className="cardTitle text-2xl w-[100%] my-auto laptop:w-[100%]  mx-auto font-serif italic text-jet font-bold py-[10px] laptop:text-4xl desktop:text-4xl">
                        <span className='my-auto text-jet'>{title}</span>
                    </p>
                </div>

                <p className='text-jet w-[100%] text-left text-xl py-[10px] laptop:w-[100%] mx-auto whitespace-pre-line'>
                    {description}
                </p>
                <p className='text-jet w-[100%] text-left text-xl py-[10px] laptop:w-[100%] mx-auto whitespace-pre-line'>
                    {description2}
                </p>
                <p className='text-jet w-[100%] text-left text-xl py-[10px] laptop:w-[100%] mx-auto whitespace-pre-line'>
                    {description3}
                </p>
            </div>

            <div className={`mx-auto`} >
                <div className='w-300 h-300' id="block-canvas"></div>
            </div>

        </div>

    );
};