import React from 'react';

const ToastNotification = ({ item, onClose }) => {
    const handleCheckout = () => {
        window.location.href = "/checkout";
    };

    return (
        <div className="fixed bottom-5 right-5 bg-white border border-jet rounded-lg shadow-lg p-4 max-w-sm flex items-center space-x-4">
            <span role="img" aria-label="cart">🎉</span>
            <div className="flex-grow">
                {/* <div className="font-bold">Item Added to Cart</div> */}
                <div className=''>Your masterpiece has been added to the cart!</div>
                <div className="flex mt-2">
                    <button className="text-jet hover:text-deepOrange font-semibold mr-2 ease-linear duration-150" onClick={handleCheckout}>Go to checkout</button>
                    {/* <button className="text-jet hover:text-deepOrange font-semibold ease-linear duration-150" onClick={onClose}>Create</button> */}
                </div>
            </div>
            <button className="text-jet opacity-[80%] hover:opacity-[100%] ease-linear duration-150" onClick={onClose}>
                <span aria-hidden="true">×</span>
            </button>
        </div>
    );
};

export default ToastNotification;
