import React from 'react';
import InfoBlockCanvas from '../PageBlocks/InfoBlockCanvas.js';

const Beta = () => {
    return (

        <div className="w-full mx-auto">
            {/* <p className="text-3xl lowercase font-bold text-center pb-[40px] desktop:text-5xl font-serif italic laptop:text-5xl">
                about <span className="text-deepOrange text-3xl desktop:text-5xl laptop:text-5xl font-serif italic lowercase">n-gen</span>
            </p> */}

            <InfoBlockCanvas title={'Our Story'}
                description={"Each of us generate an immense amount of data - terabytes of personal information that are frequently stored and monitored by the myriad of companies we interact with daily. In some sense, that data is a very intimate representation of who we are."}
                description2={"At n-gen, we recognize the intrinsic value of this data and its potential to tell your personal story. We collaborate with generative artists around the globe to transform your data into bespoke digital art pieces."}
                description3={"On n-gen, you can become a creator with your own data and make art that is unique only to you. You not only get to explore and understand your interactions with the products and services you use daily, but also celebrate your individuality by creating art that is as unique as your digital footprint."}
            />

            <div className="Box_1 grid grid-cols-1 laptop:grid-cols-1 desktop:grid-cols-1">
                <div className="top mx-auto">

                    {/* Intro */}
                    {/* <h1 className='text-base pb-[20px] desktop:text-lg laptop:text-lg'>
                        <span className="font-serif font-bold text-deepOrange italic">n-gen</span> is the first dynamic art platform where people can create art from their data, display it, and connect with others — all in one place. Linking your artwork to personal data guarantees each output is as unique and authentic as you are!
                        <br></br><br></br>
                        As your collection of data changes and grows, the art will change and produce new, exciting outputs that we refer to as "dynamic art".
                        <br></br><br></br>
                        Our long-term goal is to build community through dynamic art by authentic representating ourselves and connecting with others around us.
                    </h1> */}

                    {/* create a circular image with the file petephoto.jpg */}

                    <div className="grid grid-cols-1 items-center py-[10px]">
                    <p className="text-2xl lowercase font-bold text-left py-[20px] desktop:text-4xl font-serif italic laptop:text-4xl">faqs</p>

                    <p className='text-xl italic font-serif font-bold pb-[20px] desktop:text-2xl laptop:text-2xl'>
                        how do you handle my data?
                    </p>

                    <p className='text-base pb-[20px] desktop:text-lg laptop:text-lg'>
                        When you authenticate with Spotify or Strava, we save credentials that link our application to theirs to retrieve your data. Then, when you create art, we call the most recent data from each respective application and display it in real-time. <span className='font-bold'>We do not sell your data to any third parties.</span> 
                    </p>
                    
                    <p className='text-base pb-[20px] desktop:text-lg laptop:text-lg'>
                    The only time we are storing data is if you save your art to your collection, in which case we save the art's characteristics (e.g., the popularity of the music in the artwork, the artists, etc.) to sort the art.
                    </p>

                    <p className='text-base pb-[20px] desktop:text-lg laptop:text-lg'>
                        If you have any questions about your data, please refer to our <a href='https://ngenart.com/privacy' className="text-blue font-bold hover:text-blue/75">privacy statement</a> or reach out to me <a href="mailto:info@ngenart.com?subject=n-gen about n-gen" className="text-blue font-bold hover:text-blue/75">info@ngenart.com</a>! 
                    </p>
                    
                    <p className='text-xl italic font-serif font-bold pb-[20px] desktop:text-2xl laptop:text-2xl'>
                        how can I switch Spotify accounts?
                    </p>

                    <p className='text-base pb-[20px] desktop:text-lg laptop:text-lg'>
                        If you accidentally logged in with the incorrect Spotify account, you can switch your account by trying to login via incognito or private tab or going to Spotify and removing n-gen from connected 3rd-party applications.
                    </p>

                    <p className='text-xl italic font-serif font-bold pb-[20px] desktop:text-2xl laptop:text-2xl'>
                        will you add more music sources like Apple Music and last.fm?
                    </p>

                    <p className='text-base pb-[20px] desktop:text-lg laptop:text-lg'>
                        We’ve explored building integrations with other streaming services; however, these services provide significantly less data than Spotify. At the moment, we’re deprioritizing building integrations for these services until we can make the same type of art that is available with our Spotify integration.
                    </p>

                    <p className='text-xl italic font-serif font-bold pb-[20px] desktop:text-2xl laptop:text-2xl'>
                        what are the next data sources?
                    </p>

                    <p className='text-base pb-[20px] desktop:text-lg laptop:text-lg'>
                        We are constantly working to expand data sources to create with on n-gen. Given some of the feedback we’ve heard thus far, we’d love to explore more data in the media space, including streaming, gaming, social media, and book data. Have suggestions? Share them at <a href="mailto:info@ngenart.com?subject=n-gen about n-gen" className="text-blue font-bold hover:text-blue/75">info@ngenart.com</a>
                    </p>

                    {/* <p className='text-xl lowercase italic font-serif font-bold pb-[20px] desktop:text-2xl laptop:text-2xl'>
                        meet the team
                    </p>
                    <div className='grid grid-cols-1 laptop:grid-cols-3 items-center'>
                        <div className="mx-auto col-span-2">
                            <p className='text-base pb-[20px] desktop:text-lg laptop:text-lg'>
                                Nice to meet you and thanks for trying out the platform! I'm the founder and one-man team behind n-gen. Definitely say hello on on <a href="https://twitter.com/ngenart_co" className="text-blue font-bold hover:text-blue/75">Twitter</a> and <a href="https://www.instagram.com/ngenart_co/" className="text-blue font-bold hover:text-blue/75">Instagram</a> at <span className="font-bold">@ngenart_co</span> or send me an email at <a href="mailto:hello@ngenart.com?subject=n-gen feedback" className="text-blue font-bold hover:text-blue/75">hello@ngenart.com</a>.
                            </p>

                            <p className='text-base pb-[20px] desktop:text-lg laptop:text-lg'>
                                I'm always looking to build out the team or feedback. If you're interested in joining, learning more about the platform, or have feedback, please reach out to <a href="mailto:info@ngenart.com?subject=n-gen about n-gen" className="text-blue font-bold hover:text-blue/75">info@ngenart.com</a>!
                            </p>
                        </div>

                        <div className="mx-auto items-center">
                            <img className="mx-auto rounded w-[200px] h-[200px] rounded-[100px] border-4 border-jet border-solid" src="Assets/Images/petePhoto.jpeg" alt="Pete" />
                        </div>

                    </div> */}

                    </div>
                </div>
            </div>
        </div>


    );
};

export default Beta;