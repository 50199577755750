import React from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { Navigate, useLocation } from 'react-router-dom';
import { auth, functions, httpsCallable, db, ref, onValue, doc, getDoc, storage, uploadBytes, getDownloadURL, sRef, updateMetadata } from '../../firebase.init';
import Loading from '../../Utils/Loading';
import { useEffect } from 'react';

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const code = urlParams.get('code');


const CheckAuth = async () => {
    if (auth.currentUser != null) {
        if(code != null){
            return true;
        }
        const userId = auth.currentUser.uid;
        const ref = doc(db, "users", userId);
        const refSnap = await getDoc(ref);
        const snapData = (refSnap.data());
        if (snapData.spotifyAuthToken != undefined && snapData.spotifyAuthToken != null && snapData.spotifyAuthToken.length > 0 && snapData.spotifyUserId != undefined && snapData.spotifyUserId != null && snapData.spotifyUserId.userId.length > 0) {
            return true;
        }
        
        else {
            return false;
        }
    }
    else {
        return false;
    }
}

const RenderPage = (dest) => {
    if(dest != null){
        // Render the page
        return dest;
    }
    else{
        // Redirect to login
        window.location.href = "/spotify/login-ten";
    }
}



const SpotifyAuthRoute = ({ children }) => {

    let location = useLocation();
    const [user, loading] = useAuthState(auth)
    var isAuth = CheckAuth();
    // after check auth, set isAuth to true or false
    CheckAuth().then((result) => {
        isAuth = result;
        const setDestination = (isAuth, children) => {
            if (isAuth) {
                return children;
            }
            else {
                return null;
            }
        }
        var promise = Promise.resolve(setDestination(isAuth, children))
        var dest = setDestination(isAuth, children)
        return dest;
    }).then((dest) => {
        RenderPage(dest);
    })

    return children;

    



    // return Promise.resolve(setDestination(isAuth, children));
    // }, [])


};

export default SpotifyAuthRoute;
