import React, { useEffect, useState } from "react";
import {
    PaymentElement,
    useStripe,
    useElements,
    AddressElement,
    LinkAuthenticationElement
} from "@stripe/react-stripe-js";

export default function CheckoutForm() {
    const stripe = useStripe();
    const elements = useElements();

    const [message, setMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (!stripe) {
            return;
        }

        const clientSecret = new URLSearchParams(window.location.search).get(
            "payment_intent_client_secret"
        );

        if (!clientSecret) {
            return;
        }

        stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
            switch (paymentIntent.status) {
                case "succeeded":
                    setMessage("Payment succeeded!");
                    break;
                case "processing":
                    setMessage("Your payment is processing.");
                    break;
                case "requires_payment_method":
                    setMessage("Your payment was not successful, please try again.");
                    break;
                default:
                    setMessage("Something went wrong.");
                    break;
            }
        });
    
    }, [stripe]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js hasn't yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        setIsLoading(true);

        const { error } = await stripe.confirmPayment({
            elements,
            confirmParams: {
                // Make sure to change this to your payment completion page
                return_url: "http://localhost:3000/orders/confirmation",
            },
        });

        // Need to figure out how to get this to pass in som


        // This point will only be reached if there is an immediate error when
        // confirming the payment. Otherwise, your customer will be redirected to
        // your `return_url`. For some payment methods like iDEAL, your customer will
        // be redirected to an intermediate site first to authorize the payment, then
        // redirected to the `return_url`.
        if (error.type === "card_error" || error.type === "validation_error") {
            setMessage(error.message);
        } else {
            setMessage("An unexpected error occurred.");
        }

        setIsLoading(false);
    };

    const handleSendEmail = (paymentIntent) => {
        // Define the variables for the email template

        console.log(paymentIntent);

        // let confirmationEmailVariables = {
        //     name: shippingDetails.name,
        //     email: contactEmail,
        //     orderNumber: data.order.id,
        //     orderItems: finalOrder,
        //     shippingDetails: shippingDetails,
        // }

        // // Update template here and on emailjs server
        // emailjs.send('service_yf4l6qi', 'template_uohur09', confirmationEmailVariables, "z_yKbCxQCs9Cxr0y9")
        //     .then((result) => {
        //         console.log(result.text);
        //         alert('Thank you for your submission! We will get back to you shortly.');
        //     }, (error) => {
        //         console.log(error.text);
        //     });

        // Update template here and on emailjs server
        // emailjs.send('your_service_id', 'your_template_id', confirmationEmailVariables, "your_user_id")
        //     .then((result) => {
        //         console.log('Email sent:', result.text);
        //     }, (error) => {
        //         console.log('Email error:', error.text);
        //     });
    };

    const paymentElementOptions = {
        layout: "tabs"
    }

    return (
        <form id="payment-form" onSubmit={handleSubmit}>
            <p className="text-2xl font-bold py-[10px]">Contact Info</p>
            <LinkAuthenticationElement id="link-authentication-element" />
            <p className="text-2xl font-bold py-[10px]">Shipping Info</p>
            <AddressElement options={{ mode: 'shipping' }} />
            <p className="text-2xl font-bold py-[10px]">Payment Info</p>
            <PaymentElement id="payment-element" options={paymentElementOptions} />
            <button className="btn mt-[20px] w-full bg-jet text-white" disabled={isLoading || !stripe || !elements} id="submit">
                <span id="button-text">
                    {isLoading ? <div className="spinner" id="spinner">Wait</div> : "Pay now"}
                </span>
            </button>
            {/* Show any error or success messages */}
            {message && <div id="payment-message">{message}</div>}
        </form>
    );
}