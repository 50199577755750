import React from 'react';

const Loading = () => {
    return (
        <div className='min-h-[80vh] w-full flex justify-center items-center'>
            <img src="\Assets\Images\loading.gif" alt="loading" />
        </div>
    );
};

export default Loading;